<template>
  <el-aside :width="$store.state.isCollapse ? '64px' : '200px'">
    <el-switch class="btn" v-model="value1" @click="changeBtn" />
    <el-menu
      :collapse="$store.state.isCollapse"
      :router="true"
      :default-active="route.fullPath"
    >
      <el-menu-item index="/index">
        <el-icon>
          <HomeFilled />
        </el-icon>
        <span>首页</span>
      </el-menu-item>

      <el-menu-item index="/center">
        <el-icon>
          <Avatar />
        </el-icon>
        <span>个人中心</span>
      </el-menu-item>

      <el-sub-menu index="/user-manage" v-user>
        <template #title>
          <el-icon>
            <UserFilled />
          </el-icon>
          <span>员工管理</span>
        </template>
        <el-menu-item index="/user-manage/useradd">添加员工</el-menu-item>
        <el-menu-item index="/user-manage/userlist">员工列表</el-menu-item>
      </el-sub-menu>

      <el-sub-menu index="/games-manage">
        <template #title>
          <el-icon>
            <SwitchFilled />
          </el-icon>
          <span>账号管理</span>
        </template>
        <el-sub-menu index="CF">
          <template #title>穿越火线</template>
          <el-menu-item index="/game-manage/cf/gameadd">账号添加</el-menu-item>
          <el-menu-item index="/game-manage/cf/gamelist">账号列表</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="VALORANT">
          <template #title>无畏契约</template>
          <el-menu-item index="/game-manage/val/gameadd">账号添加</el-menu-item>
          <el-menu-item index="/game-manage/val/gamelist"
            >账号列表</el-menu-item
          >
        </el-sub-menu>
      </el-sub-menu>

      <el-menu-item index="/activity-manage">
        <el-icon>
          <Box />
        </el-icon>
        <span>点券活动</span>
      </el-menu-item>

      <el-sub-menu index="/seller-manage" v-seller>
        <template #title>
          <el-icon>
            <Document />
          </el-icon>
          <span>卖家资料</span>
        </template>
        <el-menu-item index="/seller-manage/selleradd">添加资料</el-menu-item>
        <el-menu-item index="/seller-manage/sellerlist">资料列表</el-menu-item>
      </el-sub-menu>

      <el-menu-item index="/kefucheck" v-kefucheck>
        <el-icon>
          <Service />
        </el-icon>
        <span>客服验证系统</span>
      </el-menu-item>

      <el-menu-item index="/user-operationlog" v-kefucheck>
        <el-icon>
          <EditPen />
        </el-icon>
        <span>员工操作日志</span>
      </el-menu-item>
    </el-menu>
  </el-aside>
</template>

<style lang="less" scoped>
.el-aside {
  height: 100vh;
  transition: 0.5s;
}

.btn {
  position: relative;
  right: -15px;
}
</style>

<script setup>
//导入图标组件
import {
  HomeFilled,
  Avatar,
  UserFilled,
  SwitchFilled,
  Box,
  Document,
  Service,
  EditPen,
} from "@element-plus/icons-vue";
import store from "@/store";
import { ref } from "vue";
import { useRoute } from "vue-router";

const value1 = ref(true);
const changeBtn = () => {
  store.commit("changeisCollapse");
};
// :default-active通过index添加高亮效果，route.fullparh获取当前路径，直接匹配
const route = useRoute();

// 权限删除菜单
const vUser = {
  mounted(el) {
    if (store.state.userInfo.role !== 1) {
      el.parentNode.removeChild(el);
    }
  },
};
const vKefucheck = {
  mounted(el) {
    if (store.state.userInfo.role !== 1) {
      el.parentNode.removeChild(el);
    }
  },
};

const vSeller = {
  mounted(el) {
    if (store.state.userInfo.role === 3 || store.state.userInfo.role === 4) {
      el.parentNode.removeChild(el);
    }
  },
};
</script>
