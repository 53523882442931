<template>
  <el-row :gutter="20" style="width: 1550px">
    <el-col :span="12">
      <el-card shadow="hover">
        <template #header>
          <div id="card-header">
            <span>穿越火线 账号添加</span>
          </div>
        </template>

        <el-form
          ref="gamesAddRef"
          :model="gamesAddForm"
          :rules="gamesAddFormRules"
          class="demo-ruleForm"
          status-icon
        >
          <el-form-item label="标题" prop="title">
            <el-input v-model="gamesAddForm.title" style="width: 300px" />
          </el-form-item>
          <el-form-item label="文本" prop="text">
            <el-input
              type="textarea"
              rows="3"
              v-model.trim="gamesAddForm.text"
            />
          </el-form-item>
          <el-divider border-style="double" />
          <el-row>
            <el-col :span="12">
              <el-form-item label="区服" prop="server">
                <el-cascader
                  :options="serverOptions"
                  clearable
                  placeholder="大区"
                  style="width: 170px"
                  v-model="gamesAddForm.server"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="编号" prop="number">
                <el-input
                  v-model="gamesAddForm.number"
                  style="width: 130px"
                  placeholder="纯数字"
                >
                  <template #prepend>{{
                    spliceNumber.server + spliceNumber.salesType
                  }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="类型" prop="salesType">
                <el-select
                  placeholder="自营/代售"
                  v-model="gamesAddForm.salesType"
                  style="width: 110px"
                >
                  <el-option
                    v-for="item in salesTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="排位" prop="rank">
                <el-select
                  placeholder="排位情况"
                  v-model="gamesAddForm.rank"
                  style="width: 130px"
                >
                  <el-option
                    v-for="item in rankOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="实名" prop="realNameSystem">
                <el-select
                  placeholder="二次情况"
                  v-model="gamesAddForm.realNameSystem"
                  style="width: 110px"
                >
                  <el-option
                    v-for="item in realNameSystemOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="收购客服" prop="clerk">
                <el-select
                  :loading="loading"
                  placeholder="职员"
                  v-model="gamesAddForm.clerk"
                  style="width: 100px"
                >
                  <el-option
                    v-for="item in clerkOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider border-style="double" />

          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="售价" prop="price">
                <el-input
                  style="width: 100px"
                  v-model.number="gamesAddForm.price"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="代理价" prop="AgencyPrice">
                <el-input
                  placeholder="代理拿货价"
                  v-model.number="gamesAddForm.AgencyPrice"
                  style="width: 100px"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="回收价" prop="recoveryPrice">
                <el-input
                  v-model.number="gamesAddForm.recoveryPrice"
                  style="width: 100px"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="QQ账号" prop="qqNumber">
                <el-input
                  v-model.number="gamesAddForm.qqNumber"
                  style="width: 160px"
                />
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="密码" prop="qqPassword">
                <el-input
                  v-model="gamesAddForm.qqPassword"
                  style="width: 160px"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="备注" prop="introduce">
                <el-input
                  v-model="gamesAddForm.introduce"
                  style="width: 136px"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-col>

    <el-col :span="12">
      <el-card shadow="hover">
        <el-divider content-position="left">首页图</el-divider>
        <el-upload
          class="avatar-uploader"
          action=" "
          :show-file-list="false"
          :auto-upload="false"
          accept=".jpg,.png"
          :on-change="handleChangeMain"
        >
          <img v-if="mainImageUrl" :src="mainImageUrl" class="mainImage" />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>

        <el-divider content-position="left">详情图</el-divider>

        <el-upload
          class="upload-demo"
          multiple
          :limit="30"
          :on-preview="uploadPreviewList"
          accept=".jpg,.png"
          list-type="picture-card"
          :on-change="handleChangeList"
          :on-remove="deleteListChange"
          :auto-upload="false"
          :on-exceed="onExceedList"
          :class="{ disUploadSty: isUpload }"
        >
          <el-icon><Plus /></el-icon>
        </el-upload>

        <el-form-item class="tijiao">
          <el-button type="primary" @click="submitForm">
            添加<el-icon class="el-icon--right"><DocumentAdd /></el-icon>
          </el-button>
        </el-form-item>
      </el-card>
    </el-col>
  </el-row>
  <el-dialog v-model="dialogVisible" :show-close="false" top="5vh">
    <img w-full :src="dialogImageUrl" alt="Preview Image" style="width: 100%" />
  </el-dialog>
</template>
<style lang="less" scoped>
#card-header span {
  font-size: 20px;
  font-weight: 550;
  color: #606266;
}
.el-col span {
  margin-bottom: 5px;
}
::v-deep.avatar-uploader .mainImage {
  width: 192px;
  height: 108px;
  display: block;
}
::v-deep.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

::v-deep.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #878888;
  width: 192px;
  height: 108px;
  text-align: center;
}
::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 100px !important;
  height: 100px !important;
}
.tijiao {
  position: relative;
  top: 20px;
  height: 30px;
}
::v-deep .disUploadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>

<script setup>
import { ref, reactive, watch, onMounted } from "vue";
import { Plus, DocumentAdd } from "@element-plus/icons-vue";
import { ElMessage, ElNotification } from "element-plus";
import axios from "axios";
import { useRouter } from "vue-router";
import compressImage from "@/util/compressImage";
// v-model绑定表单 reactive绑定v-model
const gamesAddForm = reactive({
  title: null, //标题
  text: null, //文本
  server: null, //区服
  number: null, //编号
  salesType: null, //类型 自营/代售
  realNameSystem: null, // 实名 可否二次
  rank: null, // 排位 可否排位
  price: null, //价格
  clerk: "", //收购客服 职员
  introduce: null, //介绍
  recoveryPrice: null, //成本价
  qqNumber: null, //账号
  qqPassword: null, //密码
  spliceNumber: null, //编号拼接
  AgencyPrice: null, // 代理价格
});

// 监听大区选项框 更改编号代码
const spliceNumber = reactive({
  server: "",
  salesType: "",
});
watch(
  () => gamesAddForm.server,
  (newValue, oldValue) => {
    if (newValue != null) {
      let letterNumber;
      switch (newValue[0]) {
        case "北部":
          letterNumber = "B";
          break;
        case "东部":
          letterNumber = "D";
          break;
        case "西部":
          letterNumber = "X";
          break;
        case "南部":
          letterNumber = "N";
          break;
      }
      spliceNumber.server = letterNumber;
    }
  }
);
// 监听销售类型
watch(
  () => gamesAddForm.salesType,
  (newValue, oldValue) => {
    if (newValue === "代售") {
      spliceNumber.salesType = "L";
    } else {
      spliceNumber.salesType = "";
    }
  }
);

const fileForm = reactive({
  fileMain: null, //主图
  fileList: [], //列表图
});

// 上传文件前 判断
const beforeUpload = async (file) => {
  //检查是否为重复图片
  const hasDuplicate = fileForm.fileList.some((item) => {
    if (item.name === file.name) {
      ElMessage.error("请检查图片是否重复上传");
      return true;
    }
    return false;
  });

  if (hasDuplicate) return false;
  // 类型
  const isJPG = file.raw.type === "image/jpeg";
  const isPNG = file.raw.type === "image/png";
  // 大小
  const isLt20M = file.raw.size / 1024 / 1024 > 20;
  const isLt3M = file.raw.size / 1024 / 1024 > 3;
  if (!(isJPG || isPNG)) {
    ElMessage.error("上传类型错误");
    return false;
  }
  if (isLt20M) {
    ElMessage.error("上传文件需小于20MB");
    return false;
  }
  if (isLt3M && !isLt20M) {
    const image = await compressImage(file.raw);
    return image;
  }
  return true;
};

// 超出文件限制
const isUpload = ref(false);
const onExceedList = () => {
  ElMessage({
    message: "最多可上传30张图片",
    type: "warning",
  });
};

// 文件列表改变 缓存文件 上传路口隐藏
// 主图
const mainImageUrl = ref();
const handleChangeMain = async (file) => {
  const processData = await beforeUpload(file);
  if (processData == true) {
    mainImageUrl.value = URL.createObjectURL(file.raw);
    fileForm.fileMain = file.raw;
  } else if (processData == false) {
    return;
  } else {
    mainImageUrl.value = URL.createObjectURL(processData);
    fileForm.fileMain = processData;
  }
};

// 详情图
const handleChangeList = async (file, fileList) => {
  const processData = await beforeUpload(file);
  if (processData == true) {
    fileForm.fileList.push(file.raw);
  } else if (processData == false) {
    return fileList.pop();
  } else {
    fileForm.fileList.push(processData);
  }
  isUpload.value = fileForm.fileList.length >= 30;
  console.log(fileForm.fileList);
};

// 删除图片
const deleteListChange = (file, fileList) => {
  fileForm.fileList = fileForm.fileList.filter(
    (image) => image.name != file.name
  );
  isUpload.value = fileForm.fileList.length >= 30;
};

// 表单提交
const router = useRouter();
const submitForm = () => {
  gamesAddRef.value.validate(async (value) => {
    if (value && ifFile()) {
      gamesAddForm.spliceNumber =
        spliceNumber.server + spliceNumber.salesType + gamesAddForm.number;
      const params = new FormData();

      // 表单添加
      for (let key in gamesAddForm) {
        if (key == "number" || gamesAddForm[key] == null) {
          continue;
        } else if (key == "spliceNumber") {
          params.append("number", gamesAddForm[key]);
        } else {
          params.append(key, gamesAddForm[key]);
        }
      }

      // 主图添加
      params.append("fileMain", fileForm.fileMain);

      // 商品图添加
      for (let i = 0; i < fileForm.fileList.length; i++) {
        params.append("fileList", fileForm.fileList[i]);
      }
      try {
        const res = await axios.post("/adminapi/game/cf/add", params, {
          headers: {
            // 配置相应请求头
            "Content-Type": "multipart/form-data",
          },
        });
        if (res.data.code === 1) {
          ElNotification({
            title: "提交成功",
            message: "请在账号列表中选择是否上架",
            type: "success",
          });
          router.push({
            path: "/game-manage/cf/gamelist",
            query: {
              date: new Date().getTime(),
            },
          });
        } else {
          ElMessage.error("提交失败" + res.data.err);
        }
      } catch (err) {
        ElMessage.error("提交失败" + err);
      }
    }
  });
};

// 点击列表中已上传图片的钩子
const uploadPreviewList = (file) => {
  dialogVisible.value = true; //显示弹框
  dialogImageUrl.value = file.url;
};

// 列表图片预览
const dialogVisible = ref(false);
const dialogImageUrl = ref("");
// 判断文件上传
const ifFile = () => {
  if (!fileForm.fileMain && !fileForm.fileList) {
    ElNotification({
      title: "提交失败",
      message: "请上传账号图片",
      type: "error",
    });
    return false;
  }
  if (!fileForm.fileMain) {
    ElNotification({
      title: "提交失败",
      message: "首页图呢？？？",
      type: "error",
    });
    return false;
  }
  if (!fileForm.fileList) {
    ElNotification({
      title: "提交失败",
      message: "详情图都不上传你卖什么？",
      type: "error",
    });
    return false;
  }
  return true;
};

// 收购客服选项
const loading = ref(true);
const clerkOptions = ref([]);
onMounted(async () => {
  try {
    loading.value = true;
    const res = await axios.get("adminapi/user/clerk");
    if (res.data.code === 1) {
      clerkOptions.value = await res.data.data.map((item) => ({
        label: item.petname,
        value: item.petname,
      }));
      loading.value = false;
    } else {
      ElMessage.error("获取收购客服列表失败" + res.data.err);
    }
  } catch (err) {
    ElMessage.error("获取收购客服列表失败" + err);
  }
});
// 校验规则
const gamesAddRef = ref();
const gamesAddFormRules = reactive({
  title: [
    {
      required: true,
      message: "标题不能为空",
      trigger: "blur",
    },
  ],
  text: [
    {
      required: true,
      message: "账号装备信息不能为空",
      trigger: "blur",
    },
  ],
  server: [
    {
      required: true,
      message: "请选择区服",
      trigger: ["change", "blur"],
    },
  ],
  salesType: [
    {
      required: true,
      message: "请选择上架类型",
      trigger: ["change", "blur"],
    },
  ],
  number: [
    {
      required: true,
      message: "请输入编号",
      trigger: ["change", "blur"],
    },
  ],
  price: [
    {
      required: true,
      type: "number",
      message: "格式错误",
      trigger: ["change", "blur"],
    },
  ],
  clerk: [
    {
      required: true,
      message: "请选择上架类型",
      trigger: ["change", "blur"],
    },
  ],
  rank: [
    {
      required: true,
      message: "请选择排位情况",
      trigger: ["change", "blur"],
    },
  ],
  realNameSystem: [
    {
      required: true,
      message: "请选择实名情况",
      trigger: ["change", "blur"],
    },
  ],
  recoveryPrice: [
    {
      required: false,
      type: "number",
      message: "格式错误",
      trigger: ["change", "blur"],
    },
  ],
  qqNumber: [
    {
      required: false,
      type: "number",
      message: "格式错误",
      trigger: ["change", "blur"],
    },
    {
      pattern: /^[1-9]{1}[0-9]{4,14}$/,
      message: "请核对qq账号是否正确",
    },
  ],
  qqPassword: [
    {
      required: false,
      message: "请输入密码",
      trigger: ["change", "blur"],
    },
  ],
  AgencyPrice: [
    {
      required: true,
      message: "输入代理拿货价格",
      trigger: ["change", "blur"],
    },
  ],
});
// 大区
const serverOptions = [
  {
    label: "北部",
    value: "北部",
    children: [
      {
        label: "北京一区",
        value: "北京一区",
      },
      {
        label: "北京二区",
        value: "北京二区",
      },
      {
        label: "北京三区",
        value: "北京三区",
      },
      {
        label: "北京四区",
        value: "北京四区",
      },
      {
        label: "北方大区",
        value: "北方大区",
      },
      {
        label: "吉林一区",
        value: "吉林一区",
      },
      {
        label: "山东一区",
        value: "山东一区",
      },
      {
        label: "山东二区",
        value: "山东二区",
      },
      {
        label: "山西一区",
        value: "山西一区",
      },
      {
        label: "河北一区",
        value: "河北一区",
      },
      {
        label: "河南一区",
        value: "河南一区",
      },
      {
        label: "辽宁一区",
        value: "辽宁一区",
      },
      {
        label: "辽宁二区",
        value: "辽宁二区",
      },
      {
        label: "辽宁三区",
        value: "辽宁三区",
      },
      {
        label: "黑龙江区",
        value: "黑龙江区",
      },
      {
        label: "河南二区",
        value: "河南二区",
      },
    ],
  },
  {
    label: "东部",
    value: "东部",
    children: [
      {
        label: "上海一区",
        value: "上海一区",
      },
      {
        label: "上海二区",
        value: "上海二区",
      },
      {
        label: "安徽一区",
        value: "安徽一区",
      },
      {
        label: "江苏一区",
        value: "江苏一区",
      },
      {
        label: "江苏二区",
        value: "江苏二区",
      },
      {
        label: "浙江一区",
        value: "浙江一区",
      },
      {
        label: "浙江二区",
        value: "浙江二区",
      },
      {
        label: "福建一区",
        value: "福建一区",
      },
      {
        label: "移动专区",
        value: "移动专区",
      },
      {
        label: "教育网区",
        value: "教育网区",
      },
    ],
  },
  {
    label: "南部",
    value: "南部",
    children: [
      {
        label: "南方大区",
        value: "南方大区",
      },
      {
        label: "湖南一区",
        value: "湖南一区",
      },
      {
        label: "广东一区",
        value: "广东一区",
      },
      {
        label: "广东二区",
        value: "广东二区",
      },
      {
        label: "广东三区",
        value: "广东三区",
      },
      {
        label: "广东四区",
        value: "广东四区",
      },
      {
        label: "广西一区",
        value: "广西一区",
      },
      {
        label: "江西一区",
        value: "江西一区",
      },
      {
        label: "湖北一区",
        value: "湖北一区",
      },
      {
        label: "湖北二区",
        value: "湖北二区",
      },
      {
        label: "广东一区",
        value: "广东一区",
      },
    ],
  },
  {
    label: "西部",
    value: "西部",
    children: [
      {
        label: "云南一区",
        value: "云南一区",
      },
      {
        label: "四川二区",
        value: "四川二区",
      },
      {
        label: "重庆一区",
        value: "重庆一区",
      },
      {
        label: "陕西一区",
        value: "陕西一区",
      },
      {
        label: "四川一区",
        value: "四川一区",
      },
    ],
  },
  {
    label: "其他",
    value: "其他",
  },
];
const salesTypeOptions = [
  { label: "自营", value: "自营" },
  { label: "代售", value: "代售" },
];

const realNameSystemOptions = [
  { label: "可二次", value: "可二次" },
  { label: "不可二次", value: "不可二次" },
];
const rankOptions = [
  { label: "可排位", value: "可排位" },
  { label: "不可排位", value: "不可排位" },
];
</script>
