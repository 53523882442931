<template>
  <el-card class="box-card" shadow="never">
    <template #header>
      <div class="card-header">
        <span>账号信息</span>
      </div>
    </template>
    <el-form
      ref="gamesAddRef"
      :model="gamesAddForm"
      :rules="gamesAddFormRules"
      class="demo-ruleForm"
      status-icon
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="gamesAddForm.title" style="width: 300px" />
      </el-form-item>
      <el-form-item label="文本" prop="text">
        <el-input type="textarea" rows="3" v-model="gamesAddForm.text" />
      </el-form-item>
      <el-divider border-style="double" />

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="区服" prop="server">
            <el-cascader
              :options="serverOptions"
              clearable
              placeholder="大区"
              style="width: 110px"
              v-model="gamesAddForm.server"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="编号" prop="number">
            <el-input
              v-model.number="gamesAddForm.number"
              style="width: 135px"
              placeholder="输入纯数字即可"
            >
              <template #prepend>{{
                spliceNumber.server + spliceNumber.salesType
              }}</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="类型" prop="salesType">
            <el-select
              placeholder="自营/代售"
              v-model="gamesAddForm.salesType"
              style="width: 110px"
            >
              <el-option
                v-for="item in salesTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="排位" prop="rank">
            <el-select
              placeholder="排位情况"
              v-model="gamesAddForm.rank"
              style="width: 135px"
            >
              <el-option
                v-for="item in rankOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="实名" prop="realNameSystem">
            <el-select
              placeholder="二次情况"
              v-model="gamesAddForm.realNameSystem"
              style="width: 110px"
            >
              <el-option
                v-for="item in realNameSystemOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="收购客服" prop="clerk">
            <el-select
              placeholder="职员"
              v-model="gamesAddForm.clerk"
              style="width: 110px"
            >
              <el-option
                v-for="item in clerkOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider border-style="double" />

      <el-row :gutter="5">
        <el-col :span="12">
          <el-form-item label="售价" prop="price">
            <el-input
              style="width: 120px"
              v-model.number="gamesAddForm.price"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="回收价" prop="recoveryPrice">
            <el-input
              v-model.number="gamesAddForm.recoveryPrice"
              style="width: 120px"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="QQ账号" prop="qqNumber">
            <el-input
              v-model.number="gamesAddForm.qqNumber"
              style="width: 160px"
            />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="密码" prop="qqPassword">
            <el-input v-model="gamesAddForm.qqPassword" style="width: 160px" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="备注" prop="introduce">
            <el-input v-model="gamesAddForm.introduce" style="width: 130px" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <div class="button">
            <el-popconfirm
              width="220"
              confirm-button-text="确定"
              cancel-button-text="取消"
              icon-color="#DC143C"
              title="你确定要删除该账号吗？"
              @confirm="deleteForm"
            >
              <template #reference>
                <el-button type="danger" :icon="Delete" plain round />
              </template>
            </el-popconfirm>
            <el-button type="primary" :icon="Edit" @click="submitForm">
              修改
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>
<style lang="less" scoped>
.box-card {
  .el-form {
    margin-top: 10px;
  }
}
.el-col span {
  margin-bottom: 5px;
}

.button {
  position: relative;
  margin-top: 10px;
  left: 60px;
}
</style>

<script setup>
import { ref, reactive, watch } from "vue";
import { Edit, Delete } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import axios from "axios";
import { useStore } from "vuex";
import { defineEmits } from "vue";
const store = useStore();

// v-model绑定表单 reactive绑定v-model
const gamesAddForm = reactive({
  _id: null,
  title: "", //标题
  text: "", //文本
  server: "", //区服
  number: "", //编号
  salesType: "", //类型 自营/代售
  realNameSystem: "", // 实名 可否二次
  rank: "", // 排位 可否排位
  price: null, // 价格
  clerk: "", // 收购客服 职员
  introduce: "", // 介绍
  recoveryPrice: null, //成本价
  qqNumber: "", // 账号
  qqPassword: "", // 密码
  isShelves: false, // 上下架
  spliceNumber: null, // 拼接后的编号
});
// 监听大区选项框 更改编号代码
const spliceNumber = reactive({
  server: "",
  salesType: "",
});
watch(
  () => gamesAddForm.server,
  (newValue, oldValue) => {
    let letterNumber;
    switch (newValue[0]) {
      case "QQ":
        letterNumber = "Q";
        break;
      case "微信":
        letterNumber = "W";
        break;
    }
    spliceNumber.server = letterNumber;
  }
);
// 监听销售类型
watch(
  () => gamesAddForm.salesType,
  (newValue, oldValue) => {
    if (newValue === "代售") {
      spliceNumber.salesType = "D";
    } else {
      spliceNumber.salesType = "";
    }
  }
);

const emit = defineEmits(["changeGameEdit"]);
// 表单删除
const deleteForm = async () => {
  try {
    const res = await axios.delete(
      `adminapi/game/val/list/delete/${gamesAddForm._id}`
    );

    if (res.data.code === 1) {
      ElMessage({
        message: `编号${gamesAddForm.number}删除成功`,
        type: "error",
      });
      emit("changeGameEdit", false);
    } else {
      ElMessage({
        message: `删除失败，系统出问题了，快去找浅白。。。`,
        type: "warning",
      });
    }
  } catch (err) {
    ElMessage.error("系统错误操作失败" + err);
  }
};

// 表单修改提交
const submitForm = () => {
  gamesAddRef.value.validate(async (value) => {
    if (value) {
      gamesAddForm.spliceNumber =
        spliceNumber.server + spliceNumber.salesType + gamesAddForm.number;
      const params = new FormData();
      for (let key in gamesAddForm) {
        params.append(key, gamesAddForm[key]);
      }
      const res = await axios.put(
        `/adminapi/game/val/put/${gamesAddForm._id}`,
        params,
        {
          headers: {
            // 配置相应请求头
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.code === 1) {
        ElMessage({
          message: `编号【${gamesAddForm.spliceNumber}】修改成功，请重新上架。`,
          type: "success",
        });
        emit("changeGameEdit", false);
      } else {
        ElMessage({
          message: `修改失败，系统出问题了，快去找浅白。。。`,
          type: "warning",
        });
      }
    }
  });
};
// 表单修改 数据渲染
const isAgencyPrice = ref(false);
watch(
  () => store.state.gameEditId,
  async (newValue) => {
    if (store.state.gameEditId) {
      try {
        const res = await axios.post("/adminapi/game/val/query", {
          _id: newValue,
        });
        isAgencyPrice.value =
          res.data.data[0].higher_ups == res.data.higher_ups;
        if (res.data.code === 1) {
          // 数据反显
          const {
            _id,
            title,
            text,
            server,
            number,
            salesType,
            realNameSystem,
            rank,
            clerk,
            introduce,
            recoveryPrice,
            qqNumber,
            qqPassword,
          } = res.data.data[0];
          gamesAddForm._id = _id;
          gamesAddForm.number = number.replace(/[a-zA-Z]+/g, "");
          gamesAddForm.title = title;
          gamesAddForm.text = text;
          gamesAddForm.salesType = salesType;
          gamesAddForm.realNameSystem = realNameSystem;
          gamesAddForm.rank = rank;
          gamesAddForm.price = res.data.data[0][res.data.higher_ups + "Price"];
          gamesAddForm.clerk = clerk;
          gamesAddForm.introduce = introduce;
          gamesAddForm.recoveryPrice = recoveryPrice;
          gamesAddForm.qqPassword = qqPassword;
          gamesAddForm.qqNumber = qqNumber;
          let str = server.split(",");
          gamesAddForm.server = str;
        } else {
          ElMessage.error("数据获取失败" + res.data.err);
        }
      } catch (err) {
        ElMessage.error("数据获取失败" + err);
      }
    }
  },
  { immediate: true }
);

// 校验规则
const gamesAddRef = ref();
const gamesAddFormRules = reactive({
  title: [
    {
      required: true,
      message: "标题不能为空",
      trigger: "blur",
    },
  ],
  text: [
    {
      required: true,
      message: "账号装备信息不能为空",
      trigger: "blur",
    },
  ],
  server: [
    {
      required: true,
      message: "请选择区服",
      trigger: ["change", "blur"],
    },
  ],
  type: [
    {
      required: true,
      message: "请选择上架类型",
      trigger: ["change", "blur"],
    },
  ],
  number: [
    {
      required: true,
      message: "请输入编号",
      trigger: ["change", "blur"],
    },
  ],
  price: [
    {
      required: true,
      type: "number",
      message: "格式错误",
      trigger: ["change", "blur"],
    },
  ],
  clerk: [
    {
      required: true,
      message: "请选择上架类型",
      trigger: ["change", "blur"],
    },
  ],
  rank: [
    {
      required: true,
      message: "请选择排位情况",
      trigger: ["change", "blur"],
    },
  ],
  realNameSystem: [
    {
      required: true,
      message: "请选择实名情况",
      trigger: ["change", "blur"],
    },
  ],
  recoveryPrice: [
    {
      required: false,
      type: "number",
      message: "格式错误",
      trigger: ["change", "blur"],
    },
  ],
});
// 大区
const serverOptions = [
  {
    label: "QQ",
    value: "QQ",
  },
  {
    label: "微信",
    value: "微信",
  },
];

const salesTypeOptions = [
  { label: "自营", value: "自营" },
  { label: "代售", value: "代售" },
];
const clerkOptions = [
  { label: "无", value: "无" },
  { label: "HAPPY", value: "HAPPY" },
  { label: "老陈", value: "老陈" },
  { label: "逸逸", value: "逸逸" },
];
const realNameSystemOptions = [
  { label: "可二次", value: "可二次" },
  { label: "不可二次", value: "不可二次" },
];
const rankOptions = [
  { label: "可排位", value: "可排位" },
  { label: "不可排位", value: "不可排位" },
];
</script>
